<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="帮扶金比例">
              <el-input-number v-model="form.help_fee_scale"></el-input-number>%
            </el-form-item>
            <el-form-item label="帮扶金解冻">
              金额
              <el-input-number v-model="form.help_fee_change_to_cost_scale"></el-input-number>%
              划入 商城消费账户，其余划转至 商城佣金账户
            </el-form-item>
            <el-form-item label="帮扶金解冻规则【多选】"><br>
              <el-checkbox-group v-model="helpFunc">
                <div>
                  <el-checkbox label="link-agent">
                    自身邀请关系链下要有至少<el-input-number v-model="form.help_fee_change_limit_total_invite_agent"></el-input-number>个代理
                  </el-checkbox>
                </div>
                <div>
                  <el-checkbox label="help-user-boss">两个帮扶用户须升级为老板</el-checkbox>
                </div>
                <div>
                  <el-checkbox label="help-user-link-agent">
                    两个帮扶用户邀请关系链合计至少有<el-input-number v-model="form.help_fee_change_limit_total_help_user_link_agent"></el-input-number>个代理
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="代理升级老板" class="flex-def">
              需直推用户中存在
              <el-input-number v-model="form.up_boss_need_invite_agent_num"></el-input-number>
              个批发商代理
            </el-form-item>
            <el-form-item label="升级老板留人策略">
              <el-radio-group v-model="form.up_boos_retain_person">
                <el-radio :label="0">只留帮扶用户</el-radio>
                <el-radio :label="1">留帮扶用户及帮扶用户邀请关系链</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="代理直推激活卡订单奖励[可重复获取]">
              <el-input-number v-model="form.agent_invite_award_fee"></el-input-number>元
            </el-form-item>
            <el-form-item label="老板直推激活卡订单奖励[可重复获取]">
              <el-input-number v-model="form.boss_invite_award_fee"></el-input-number>元
            </el-form-item>
            <el-form-item label="老板见单奖励">
              <el-input-number v-model="form.jian_dan_award_fee"></el-input-number>元
            </el-form-item>
            <el-form-item label="老板平级奖励">
              <el-input-number v-model="form.ping_ji_award_scale"></el-input-number>%
            </el-form-item>
            <el-form-item label="平级奖励发放层级">
              <el-input-number v-model="form.ping_ji_layer"></el-input-number>层
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "mode-rule",
  components: {
  },
  data() {
    return {
      helpFunc:[],

      form: {
        help_fee_scale:0,
        help_fee_change_to_cost_scale:0,
        help_fee_change_func:"",
        help_fee_change_limit_total_invite_agent:0,
        help_fee_change_limit_total_help_user_link_agent:0,

        up_boss_need_invite_agent_num:0,
        up_boos_retain_person:0,

        agent_invite_award_fee:0,
        boss_invite_award_fee:0,
        jian_dan_award_fee:0,
        ping_ji_award_scale:0,
        ping_ji_layer:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.card.plugin.wholesaler.modeRule().then(res => {
        if (res.help_fee_change_func !== ""){
          this.helpFunc = res.help_fee_change_func.split(",");
        }
        this.form = res;
      })
    },
    edit() {
      if(this.helpFunc.length > 0)this.form.help_fee_change_func = this.helpFunc.join(",");
      this.$u.api.card.plugin.wholesaler.modeRuleEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>